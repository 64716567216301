import { useState } from 'react'
import { gql } from '@apollo/client'
import { useMutation } from '@apollo/react-hooks'

import Form from 'antd/lib/form'
import Switch from 'antd/lib/switch'
import message from 'antd/lib/message'
import PageHeader from 'antd/lib/page-header'

const UPDATE_MERCHANT_SEO_ENABLED = gql`
  mutation updateMerchantSeoEnabled($merchantId: uuid!, $seoEnabled: Boolean!) {
    update_merchants(
      where: { id: { _eq: $merchantId } }
      _set: { is_seo_enabled: $seoEnabled }
    ) {
      affected_rows
      returning {
        is_seo_enabled
      }
    }
  }
`

const SeoForm = (props) => {
  const { merchant, client } = props
  const [toggle, setToggle] = useState<Boolean>(
    Boolean(merchant?.is_seo_enabled)
  )

  const [updateMerchant] = useMutation(UPDATE_MERCHANT_SEO_ENABLED, {
    client: client
  })

  const handleToggle = () => {
    const newToggle = !toggle

    message.loading(
      `${newToggle ? 'Enabling' : 'Disabling'} SEO... Please wait.`
    )
    setToggle(newToggle)

    updateMerchant({
      variables: {
        merchantId: merchant.id,
        seoEnabled: newToggle
      }
    })
      .then((result) => {
        message.destroy()
        message.success(`SEO ${newToggle ? 'enabled' : 'disabled'}.`, 1)
      })
      .catch((err) => {
        message.destroy()
        message.error(`Unable to update ${err.message}`, 1)
      })
  }

  return (
    <>
      <PageHeader title='SEO' />
      <Form labelCol={{ span: 12 }} wrapperCol={{ span: 12 }}>
        <Form.Item label='Enabled SEO'>
          <Switch
            defaultChecked={toggle.valueOf()}
            onChange={handleToggle}
            checkedChildren='Enabled'
            unCheckedChildren='Disabled'
          />
        </Form.Item>
      </Form>
      <br />
    </>
  )
}

export default SeoForm
