import PropTypes from 'prop-types'
import { useMutation } from '@apollo/react-hooks'

import { Form, message, PageHeader, Select, Row, Col } from 'antd'
import { UPDATE_MERCHANT_SECTOR, UPDATE_MERCHANT_ICP } from './MerchantQueries'
import SubscriptionPlanContainer from './Settings/SubscriptionPlanContainer'
import TransactionPlanSelect from './TransactionPlanSelect'
import DeliverectForm from './Settings/DeliverectForm'
import TesterForm from './Settings/TesterForm'
import AccountForm from './Settings/AccountForm'
import AppForm from './Settings/AppForm'
import ComposerForm from './Settings/ComposerForm'
import CRMForm from './Settings/CRMForm'
import FranchiseForm from './Settings/FranchiseForm'
import PublishForm from './Settings/PublishForm'
import SeoForm from './Settings/SeoForm'
import FreeOrderCommissionContainer from './Settings/FreeOrderCommissionContainer'

const { Option } = Select

type Sector = {
  [name: string]: string
}
export const SECTORS: Sector = {
  bst: 'Bakeries & Sweet Treats',
  bba: 'Bars, Breweries & Alcohol',
  ccs: 'Cafes & Coffee Shops',
  dgp: 'Delis, Groceries & Provisions',
  qsr: 'QSR / Fast Casual',
  res: 'Restaurants',
  htl: 'Hotels',
  bn: 'Big Night',
  unknown: 'Unassigned Sector'
}

export const ICPS = {
  icp: 'ICP',
  strategic: 'Strategic',
  'non-icp': 'Non-ICP',
  null: 'None'
}

const MerchantSettings = (props) => {
  const [updateMerchantSector] = useMutation(UPDATE_MERCHANT_SECTOR, {
    client: props.client
  })

  const [updateMerchantICP] = useMutation(UPDATE_MERCHANT_ICP, {
    client: props.client
  })

  const updateSector = (sector) => {
    message.loading('Updating... Please wait.')
    updateMerchantSector({
      variables: {
        merchantId: props.merchant.id,
        sector: sector
      }
    }).then((result) => {
      message.destroy()
      message.success('Merchant updated', 1)
    })
  }

  const updateICP = (icp) => {
    message.loading('Updating... Please wait.')
    updateMerchantICP({
      variables: {
        merchantId: props.merchant.id,
        icp: icp
      }
    }).then((result) => {
      message.destroy()
      message.success('Merchant updated', 1)
    })
  }

  return (
    <Row>
      <Col>
        <TesterForm {...props} />
        <PageHeader title='KPI' />
        <Form labelCol={{ span: 12 }} wrapperCol={{ span: 12 }}>
          <Form.Item label='Sector'>
            <Select
              defaultValue={props.merchant.sector}
              style={{ width: 250 }}
              onChange={updateSector}
            >
              {Object.entries(SECTORS).map((sector) => {
                return (
                  <Option key={sector[0]} value={sector[0]}>
                    {sector[1]}
                  </Option>
                )
              })}
            </Select>
          </Form.Item>
        </Form>
        <Form labelCol={{ span: 12 }} wrapperCol={{ span: 12 }}>
          <Form.Item label='ICP'>
            <Select
              defaultValue={props.merchant.icp}
              style={{ width: 250 }}
              onChange={updateICP}
            >
              {Object.entries(ICPS).map((icp) => {
                return (
                  <Option key={icp[0]} value={icp[0]}>
                    {icp[1]}
                  </Option>
                )
              })}
            </Select>
          </Form.Item>
        </Form>
        <br />
        <DeliverectForm {...props} />
        <br />
        <SubscriptionPlanContainer {...props} />
        <FreeOrderCommissionContainer {...props} />
        <TransactionPlanSelect {...props} />
        <AccountForm {...props} />
        <AppForm {...props} />
        <ComposerForm {...props} />
        <CRMForm {...props} />
        <FranchiseForm {...props} />
        <PublishForm {...props} />
        <SeoForm {...props} />
        {/* <Alert
        message='Please be very very careful'
        description={
          <p>Changing merchant slug/subdomain will take effect immediately</p>
        }
        type='error'
        showIcon
      />
      <PageHeader title='Merchant' />
      <Form
        labelCol={{ span: 12 }}
        wrapperCol={{ span: 12 }}
        onSubmit={updateSlug}
      >
        <Form.Item label='Slug'>
          <Input name='slug' defaultValue={props.merchant.slug} />
          <Button htmlType='submit'>Save</Button>
        </Form.Item>
      </Form> */}
      </Col>
    </Row>
  )
}

MerchantSettings.propTypes = {
  client: PropTypes.object,
  merchant: PropTypes.object,
  refetch: PropTypes.func
}

export default MerchantSettings
